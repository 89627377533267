.login-component {
	font-family: var(--fontStack);
	font-weight: 500;
	.login-form {
		max-width: 300px;
	}
	.login-form-forgot {
		float: right;
	}
	.login-form-button {
		width: 100%;
	}

	.heading-top {
		margin-top: 0 !important;
		font-family: var(--fontStack);
	}

	.left-margin {
		margin-left: 60px;
	}

	input {
		border: 1px solid white;
		border-radius: 5px;
		width: 100%;
		padding: 8px;
		outline: 0;
	}

	.ant-form-item-explain-error {
		margin-bottom: 4%;
	}

	label {
		color: var(--brand-white);
	}

	p {
		cursor: pointer;
		text-decoration: underline;
		color: var(--brand-light-blue);
	}

	.back-pointer {
		font-family: var(--fontStack);
		font-size: var(--font-size-12);
		color: var(--brand-white);
		margin-left: 8px;
		margin-bottom: 0;
	}

	.primary-button {
		position: relative;
		width: 341px;
		height: 44px;
		margin-top: 8px;
		background: var(--brand-white);
		border-radius: 4px;
		border: 0;
		color: var(--brand-black);

		&:hover {
			border-color: var(--brand-black);
			background-color: var(--brand-light-blue);

			span {
				color: var(--brand-white);
			}
		}

		span {
			height: 19px;
			font-family: var(--fontStack);
			font-style: normal;
			font-weight: normal;
			font-size: var(--font-size-16);
			line-height: 19px;
			text-align: center;

			color: var(--brand-black);
		}
	}
}

.alert-danger {
	font-family: var(--fontStack);
	font-style: normal;
	font-weight: normal;
	font-size: var(--font-size-16);
	color: var(--brand-white);
	margin-bottom: 4%;
}
