.page-head-section {
	display: flex;
	margin-bottom: var(--font-size-40);
	padding-top: var(--font-size-32);
	.page-heading {
		max-width: 100%;
		overflow: hidden;
		word-break: break-word;
		font-family: var(--fontStack);
		font-style: normal;
		font-weight: 700;
		font-size: var(--font-size-36);
		line-height: var(--font-size-44);
		color: rgb(0 0 0 / 80%);
		float: left;
	}

	.page-heading-wrapper {
		width: 100%;
		display: flex;
		overflow-x: scroll;
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none;
		&::-webkit-scrollbar {
			display: none;
		}
		& > * {
			margin-right: 8px;
		}
	}

	.page-heading-desc {
		/* Paragraph-small */
		font-style: normal;
		font-weight: 400;
		font-size: var(--font-size-14);
		line-height: var(--font-size-17);
		/* identical to box height */
		/* Primary */
		color: rgba(0, 0, 0, 0.8);
		padding-bottom: var(--font-size-15);
		margin-top: var(--font-size-16);
	}

	.page-heading-mark-complete {
		margin-top: 6px;
		margin-left: 4px;
		padding: 3px;
		padding-right: var(--font-size-10);
		background: var(--brand-white);
		border: 1px solid rgba(0, 0, 0, 0.8);
		border-radius: 4px;
		height: var(--font-size-28);
		display: inline-block;
	}

	.page-heading-mark-completed {
		margin-top: 6px;
		margin-left: 8px;
		padding: 5px;
		padding-right: 10px;
		display: inline-block;
	}

	.breadcrumb-link,
	.breadcrumb-page {
		font-family: var(--fontStack);
		font-size: var(--font-size-36);
		font-weight: 700;
		color: var(--brand-black);
	}

	.breadcrumb-link-container {
		position: relative;
		width: fit-content;
		height: fit-content;
		&::after {
			position: absolute;
			content: '';
			height: 2px;
			width: 100%;
			background: var(--brand-black);
			left: 0;
			bottom: 0;
		}
	}

	.page-heading-action {
		float: right;
		margin-left: 5px;
		button {
			min-width: 99px;
			height: 44px;
			background: rgba(0, 0, 0, 0.8);
			border-radius: 4px;
			span {
				font-family: var(--fontStack);
				font-style: normal;
				font-weight: 400;
				font-size: var(--font-size-16);
				line-height: 19px;
				text-align: center;
				color: var(--brand-white);
			}
		}
	}
}
