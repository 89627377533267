.welcome-page {
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--list-background);

	.ant-card-head-title {
		font-family: var(--fontStack);
		font-size: var(--font-size-30);
		font-weight: 700;
	}

	.vertical-center {
		display: flex;
		align-items: center;
		min-height: 100vh;
		padding: 0% 25% 0 25%;
		width: 'fit-content';
	}

	.confirm-pass-info {
		font-family: var(--fontStack);
		font-size: var(--font-size-20);
		color: var(--brand-black);
		text-decoration: underline;
	}

	.confirm-pass-req-value {
		font-family: var(--fontStack);
		font-size: var(--font-size-12);
		color: var(--brand-black);
		margin-left: 4px;
	}
}
