.icon-letter {
	height: var(--font-size-36);
	width: var(--font-size-36);
	background: var(--icon-background);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;

	.letter {
		color: var(--brand-white);
	}
}
