:root {
	// Brand: Font
	--fontStack: Nunito, Helvetica, Arial, sans-serif;

	//brand colors
	--brand-dark-blue: #0c0826;
	--brand-light-blue: #32def6;
	--brand-white: #fff;
	--brand-black: #000;
	--brand-blue: blue;

	//family tree light bulb
	--tree-red: #ff0000;
	--tree-yellow: #ffc000;
	--tree-green: #69e53d;
	--tree-grey: grey;

	//deactivate button
	--deactivate-btn-background: rgb(199, 192, 192);
	--deactivate-btn-color: rgb(242, 42, 42);
	--round-btn-background: rgb(199, 192, 192);

	//delay traffic colors
	--delay-high: red;
	--delay-medium: orange;
	--delay-low: green;

	//risk traffic colors
	--risk-extreme: rgba(235, 87, 87, 0.5);
	--risk-high: rgba(242, 201, 76, 0.5);
	--riks-medium: #e0e0e0;
	--risk-moderate: rgba(111, 207, 151, 0.5);
	--risk-low: rgba(111, 207, 151, 0.5);

	//icon letter background
	--icon-background: #828282;

	//list background
	--list-background: #f2f2f2;

	//font size
	--font-size-10: 10px;
	--font-size-12: 12px;
	--font-size-14: 14px;
	--font-size-15: 15px;
	--font-size-16: 16px;
	--font-size-17: 17px;
	--font-size-18: 18px;
	--font-size-20: 20px;
	--font-size-24: 24px;
	--font-size-28: 28px;
	--font-size-29: 29px;
	--font-size-30: 30px;
	--font-size-32: 32px;
	--font-size-36: 36px;
	--font-size-40: 40px;
	--font-size-44: 44px;
	--font-size-48: 48px;

	//component header
	--header-height: 60px;
	--header-background: #0c0826;
	//logo size
	--header-img-height: 35px;
	--header-img-width: 35px;
	//project name font size
	--header-project-font-size: 24px;
	--header-project-font-color: rgba(255, 255, 255, 0.8);
	//user name font size
	--header-author-height: 16px;
	--header-author-font-size: 16px;
	--header-author-font-weight: 500;
	--header-author-font-family: Roboto;
	--header-author-font-line-height: 19px;
	--header-author-font-color: #333333;
	--header-role-height: 12px;
	--header-role-width: 81px;
	--header-role-font-family: var(--fontStack);
	--header-role-font-size: 10px;
	--header-role-line-height: 12px;
	--header-role-font-color: #828282;
}
