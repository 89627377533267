.forget-password-page {
	height: 100vh;
	font-family: var(--fontStack);
	font-size: var(--font-size-15);

	.vertical-center {
		display: flex;
		align-items: center;
		min-height: 100vh;
		padding: 0% 25% 0% 20%;
	}

	.login-form-button {
		width: 100%;
		font-weight: 400;
	}

	.forget-password-info {
		font-weight: 400;
	}

	.forget-password-info-bold {
		font-weight: bold;
	}

	.ant-card-head-title {
		font-weight: bold;
		font-size: var(--font-size-20);
	}
}
