.sidebar-component {
	left: 0;
	top: 0;
	background: var(--brand-dark-blue);
	height: 100%;
	width: 100%;

	li {
		border: none;
	}

	li:hover {
		border: none;
	}
	li:focus-visible {
		border: none;
	}

	.ant-menu-inline,
	.ant-menu-vertical,
	.ant-menu-vertical-left {
		border-right: 0 solid #f0f0f0;
	}

	.ant-menu-submenu-arrow {
		visibility: hidden;
	}

	a {
		color: inherit; /* blue colors for links too */
		text-decoration: inherit; /* no underline */
	}

	a.nostyle:link {
		text-decoration: inherit;
		color: inherit;
		cursor: auto;
	}

	a.nostyle:visited {
		text-decoration: inherit;
		color: inherit;
		cursor: auto;
	}

	.title-bar {
		height: 50px;
		padding-left: 40px;
		padding-top: 15px;
		font-family: var(--fontStack);
		font-style: normal;
		font-weight: bold;
		font-size: var(--font-size-14);
		line-height: 29px;
		color: var(--brand-white);
		display: inline-block;

		.logo {
			width: 25px;
			height: 25px;
			display: inline-grid;
			margin-right: 4px;
		}
	}

	.ant-menu {
		background: var(--brand-dark-blue);
	}

	div.menu-section > li.ant-menu-item,
	div.menu-secton > li.ant-menu-submenu {
		padding-left: var(--font-size-12) !important;
	}

	div.ant-menu-submenu-title {
		padding-right: 0 !important; //comment this if it breaks
	}

	.ant-menu-title-content {
		margin-left: 4px !important;
	}

	.ant-menu-title-content,
	.ant-menu-title-content a {
		font-family: var(--fontStack);
		font-style: normal;
		font-weight: bold;
		font-size: var(--font-size-14);
		line-height: 17px;
		color: #f2f2f2;
	}

	.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
		background: var(--brand-light-blue);
	}

	.ant-menu-submenu-title:hover {
		background: var(--brand-light-blue);
	}

	.ant-menu-item:hover {
		background: var(--brand-light-blue);
	}

	.ant-menu-sub.ant-menu-inline {
		background: var(--brand-dark-blue);

		.ant-menu-item.ant-menu-item-only-child span {
			font-family: var(--fontStack);
			font-style: normal;
			font-weight: normal;
			color: #f2f2f2;
		}
	}
	.rfsu-container {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		padding: 25px 55px 15px 55px;

		h3 {
			color: var(--brand-white);
			font-weight: 400;
			font-size: var(--font-size-20);
			margin: 0;
			text-align: center;
		}
	}
}
