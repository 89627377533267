.tags-container {
	width: 100%;
}

.tags-filter-section {
	display: flex;
	align-items: center;
	width: 100%;
	margin-bottom: 20px;

	& > * {
		margin-right: 8px;
		width: 250px;
	}

	.tags-filter-text {
		font-family: var(--fontStack);
		font-size: var(--font-size-14);
		font-weight: 400;
		color: rgba(0, 0, 0, 0.8);
	}

	.tags-filter-input-container {
		width: 100%;
		height: 36px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: var(--brand-white);
		box-sizing: border-box;
		padding-right: 10px;

		input {
			height: 100%;
			width: 90%;
			font-family: var(--fontStack);
			font-size: var(--font-size-14);
			font-weight: 400;
			color: rgba(0, 0, 0, 0.8);
			padding-left: 10px;
			border: 0;
			outline: 0;
		}
	}
}

.add-tag-container {
	.info-text {
		font-size: var(--font-size-14);
		font-weight: 400;
		color: rgba(0, 0, 0, 0.8);
		margin-right: 7px;
	}
}
