.export-container {
	.ant-checkbox-group,
	.checkbox-group,
	.ant-radio-group {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
	}

	.checkbox-group .ant-checkbox-wrapper {
		margin-left: 0;
	}
}

.round-deactive-button {
	background: var(--deactivate-btn-background);
	width: 123px;
	height: 44px;
	display: flex;
	align-items: center;
	font-family: var(--fontStack);
	font-size: var(--font-size-14);
	font-weight: 400;
	border: 0px;
	padding: 0;
	border-radius: var(--font-size-20);
	color: var(--deactivate-btn-color);
	cursor: pointer;
	justify-content: center;
}

.round-button {
	background: var(--round-btn-background);
	width: 160px;
	height: 44px;
	display: flex;
	align-items: center;
	font-family: var(--fontStack);
	font-size: var(--font-size-16);
	border: 0px;
	padding: 8px 15px;
	border-radius: var(--font-size-20);
	color: var(--brand-black);
	cursor: pointer;
	justify-content: space-between;
}

.icon-button {
	background: black;
	width: 160px;
	height: 44px;
	display: flex;
	font-family: var(--fontStack);
	font-size: var(--font-size-16);
	font-weight: 400;
	border: 0px;
	padding: 12px;
	border-radius: 5px;
	color: var(--brand-white);
	cursor: pointer;
	align-items: center;
	justify-content: center;
	position: relative;

	img {
		position: absolute;
		left: 12px;
		top: 50%;
		transform: translateY(-50%);
		height: var(--font-size-16);
		width: var(--font-size-16);
	}

	a {
		font-family: var(--fontStack);
		font-size: var(--font-size-16);
		font-weight: 400;
		color: var(--brand-white);
	}
}

.round-active-button {
	background: var(--round-btn-background);
	width: 123px;
	height: 44px;
	display: flex;
	align-items: center;
	font-family: var(--fontStack);
	font-size: var(--font-size-14);
	font-weight: 400;
	border: 0px;
	padding: 0;
	border-radius: var(--font-size-20);
	cursor: pointer;
	justify-content: center;
	&:hover {
		background: var(--deactivate-btn-color);
		color: var(--brand-white) !important;
	}
}
