.search-header {
	height: 60px;
	background: #0c0826;
	position: relative;
	box-sizing: border-box;
	padding: 0 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.ant-dropdown-link {
		span {
			color: var(--brand-white);
		}
	}

	.search-center-block {
		position: absolute;
		left: 30%;
		transform: translateX(-50%);
		height: 100%;
		display: flex;
		align-items: center;

		img {
			height: 35px;
			width: 35px;
			margin-right: 12px;
		}

		.project-name {
			font-weight: 700;
			font-size: var(--header-project-font-size);
			color: var(--header-project-font-color);
			margin: 0;
		}
	}

	.logo-block {
		justify-content: center;
		align-items: center;
		height: 100%;		
	}

	.logo-version {
		text-align: center;
		color: white;
		line-height: 1px;
	}

	.item-block {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.search-box {
		height: 36px;
		width: 250px;
		margin-top: 12px;
		margin-bottom: 12px;
	}

	.admin-switch {
		margin-left: 16px;
		cursor: pointer;
	}

	.author-box {
		display: flex;
		margin-left: 23px;
		margin-top: 12px;
		margin-bottom: 12px;

		.author-icon {
			width: 30px;
		}

		.author-name {
			height: var(--header-author-height);
			font-family: var(--header-author-font-family);
			font-style: normal;
			font-weight: var(--header-author-font-weight);
			font-size: var(--header-author-font-size);
			line-height: var(--header-author-font-line-height);
			color: var(--header-author-font-color);
			margin-left: 10px;

			span {
				display: block;
			}

			.role {
				width: var(--header-role-width);
				height: var(--header-role-height);
				font-family: var(--header-role-font-family);
				font-style: normal;
				font-weight: normal;
				font-size: var(--header-role-font-size);
				line-height: var(--header-role-line-height);
				color: var(--header-role-font-color);
			}
		}
	}
}

.page-link {
	color: var(--brand-black);
	cursor: pointer;
	text-decoration: underline;
}
