.login-page {
	height: 100vh;
	a {
		color: var(--brand-white); /* blue colors for links too */
		text-decoration: inherit; /* no underline */
	}

	a.nostyle:link {
		text-decoration: inherit;
		color: inherit;
		cursor: auto;
	}

	a.nostyle:visited {
		text-decoration: inherit;
		color: inherit;
		cursor: auto;
	}

	.header {
		width: 100%;
		height: 64px;
		left: 0;
		top: 0;
		background: #0c0826;
	}

	.right-sidebar {
		left: 0%;
		right: 0%;
		top: 0%;
		bottom: 0%;
		background: rgb(0 0 0 / 40%);
		opacity: 0.5;
		height: calc(100vh - 64px);
	}

	.left-sidebar {
		left: 0%;
		right: 0%;
		top: 0%;
		bottom: 0%;
		min-height: calc(100vh - 64px);
		background-color: #0c0826;

		.left-margin {
			margin-left: 60px;
		}

		.heading-top {
			margin-top: 25%;
		}
		.heading {
			width: 142px;
			height: 58px;
			left: 60px;

			/* Heading-1 */

			font-family: Nunito;
			font-style: normal;
			font-weight: bold;
			font-size: var(--font-size-48);
			line-height: 58px;

			/* identical to box height */
			color: var(--brand-light-blue);
		}

		.primary-button {
			position: relative;
			width: 341px;
			height: 44px;
			margin-top: 55px;
			background: var(--brand-white);
			border-radius: 4px;
			border: 0;
			color: var(--brand-black);

			&:hover {
				border-color: var(--brand-black);
				background-color: var(--brand-light-blue);

				span {
					color: var(--brand-white);
				}
			}

			span {
				height: 19px;
				font-family: var(--fontStack);
				font-style: normal;
				font-weight: normal;
				font-size: var(--font-size-16);
				line-height: 19px;
				text-align: center;

				color: var(--brand-black);
			}
		}
	}
}
