@import 'antd/dist/antd.min.css';

html,
body {
	height: 100%;
	min-height: 100%;
}

.App {
	// min-width: 100vw;
	width: 100%;
	min-height: 100vh;
	height: 100%;
}

.ant-layout {
	overflow: hidden; //change this when css breaks
}

.ant-btn-primary {
	border-color: inherit;
	&:hover {
		border-color: inherit;
	}
}

a {
	color: white;
}

li {
	border: none !important;
}

.ant-menu-item a:hover {
	color: white;
}

.ant-picker {
	width: 100%;
}

.ant-modal-content,
.ant-modal-body {
	width: fit-content;
}

.ant-input[disabled],
.ant-input-number-disabled,
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
	.ant-select-selector {
	color: black;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
	background: #61ffff !important;
}

a:active,
a:focus,
a:hover {
	text-decoration: none;
	outline: 0;
	color: white;
}

a.nostyle:link {
	text-decoration: inherit;
	color: inherit;
	cursor: white;
}

a.nostyle:visited {
	text-decoration: inherit;
	color: white;
	cursor: auto;
}

//hide scroll bar arrows
::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background: #9e9797;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.lds-facebook {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-facebook div {
	display: inline-block;
	position: absolute;
	left: 8px;
	width: 16px;
	background: #000;
	animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
	left: 8px;
	animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
	left: 32px;
	animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
	left: 56px;
	animation-delay: 0;
}
@keyframes lds-facebook {
	0% {
		top: 8px;
		height: 64px;
	}
	50%,
	100% {
		top: 24px;
		height: 32px;
	}
}
